import { GetStaticPropsContext } from 'next'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Link from 'next/link'

import { book } from 'src/init/book'

import { Header, Layout, PageNotFound } from 'src/components/common'
import { Button } from 'src/components/ui'

import { getLocaleFromCtx } from 'src/utils'

export const getStaticProps = async (ctx: GetStaticPropsContext) => {
  const localeGroups = ['common', 'buttons']

  return {
    props: { ...(await serverSideTranslations(getLocaleFromCtx(ctx), localeGroups)) },
  }
}

const NotFound = () => {
  const { t } = useTranslation()

  return (
    <Layout
      header={<Header title={t('titles.page_not_found')} />}
      footer={
        <div className='p-2'>
          <Button block asChild>
            <Link href={book.dashboard}>{t('buttons:go_back_home')}</Link>
          </Button>
        </div>
      }
    >
      <PageNotFound />
    </Layout>
  )
}

export default NotFound
